<template>
  <div style="width: 100%; height: 100%">
    <div class="mian_box" v-loading="loading">
      <div style="display: flex; width: 100%">
        <transition name="el-zoom-in-center">
          <div class="left1" v-show="show2">
            <div
              style="
                height: 40%;
                margin-top: 45%;
                margin-left: 10%;
                display: flex;
                flex-wrap: wrap;
              "
            >
              <div class="scroeItem">
                <div class="scroe">
                  {{ done.length + noDone.length + error.length }}
                </div>
                <div>题数</div>
              </div>
              <div class="scroeItem">
                <div class="scroe">{{ done.length + error.length }}</div>
                <div>已完成</div>
              </div>
              <div class="scroeItem">
                <div class="scroe">100</div>
                <div>总分</div>
              </div>
              <div class="scroeItem">
                <div class="scroe1">{{ examScore }}</div>
                <div>得分</div>
              </div>
            </div>
          </div>
        </transition>
        <div class="right1">
          <p class="title">答题结果</p>
          <transition name="el-zoom-in-center">
            <div>
              <span>正确</span>
              <div class="item item1" v-for="(i, index) in done" :key="index">
                <span>{{ i }}</span>
              </div>
            </div>
          </transition>
          <transition name="el-zoom-in-center">
            <div>
              <span>错误</span>
              <div class="item item2" v-for="(i, index) in error" :key="index">
                <span @click="clickItem(i.index, i.id)">{{ i.index }}</span>
              </div>
            </div>
          </transition>
          <transition name="el-zoom-in-center">
            <div>
              <span>跳过</span>
              <div class="item item3" v-for="(i, index) in noDone" :key="index">
                <span>{{ i }}</span>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="btn">
      <el-button type="success" icon="el-icon-refresh-right" @click="restart"
        >重做</el-button
      >
      <el-button type="danger" icon="el-icon-document-checked"
        >已交卷</el-button
      >
    </div>
    <el-dialog title="错误" :visible.sync="showDialog" width="25%">
      <div>
        <div class="qustion">
          <div class="qustion_item">
            {{ itemIndex }}、 {{ dataItem.question }}
          </div>
        </div>
        <div v-if="dataItem.isSelect != 1">
          <el-button
            style="
              border-radius: 50px !important;
              width: 50px;
              margin-left: 10px;
            "
            :class="{ 'btn-active': dataItem.answer == '是' }"
            round
            >是</el-button
          >
          <el-button
            style="border-radius: 50px !important; width: 50px"
            :class="{ 'btn-active': dataItem.answer == '否' }"
            round
            >否</el-button
          >
        </div>
        <p v-if="dataItem.isSelect == 1" style="margin-top: 20px">
          选择答案：<span style="color: red">{{ dataItem.answer }}</span>
        </p>
        <p style="margin-top: 20px">正确答案：{{ dataItem.quesAnswer }}</p>
        <p style="margin-top: 5px">应知应会：{{ dataItem.interpretation }}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showDialog = false">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { deleteMethod, get } from '../../api/http'
export default {
  data() {
    return {
      show2: true,
      examScore: 0,
      done: [],
      noDone: [],
      error: [],
      showDialog: false,
      dataItem: {},
      itemIndex: 0,
      questionList: [],
      loading:false,
    }
  },
  mounted() {
    this.done = JSON.parse(this.$route.query.done)
    this.noDone = JSON.parse(this.$route.query.noDone)
    this.error = JSON.parse(this.$route.query.error)
    this.getData()
  },
  methods: {
    getData() {
      get(
        (this.$route.query.queryIndex == 3
          ? '/api/ThemeExamPlan/GetByUserId'
          : '/api/ExamPlan/GetByUserId') +
          '?UserId=' +
          JSON.parse(sessionStorage.getItem('userInfo')).id
      ).then((resp) => {
        if (resp.code == 200) {
          var list = resp.data.filter((i) => {
            return i.id == this.$route.query.id
          })
          this.examScore = list[0].examScore
        }
      })
    },
    clickItem(index, id) {
      this.showDialog = true
      this.itemIndex = index
      get(
        (this.$route.query.queryIndex == 3
          ? '/api/ThemeExamPlanList/GetById'
          : '/api/ExamPlanList/GetById') +
          '?Id=' +
          id
      ).then((resp) => {
        if (resp.code == 200) {
          this.dataItem = resp.data
        }
      })
    },
    restart() {
      console.log(this.$route.query.queryIndex)
      this.$confirm('是否清空已答问题全部重新开始？')
        .then(() => {
          this.loading = true;
          deleteMethod(
            (this.$route.query.queryIndex == 3
              ? '/api/ThemeExamPlan'
              : '/api/ExamPlan') +
              '?Id=' +
              this.$route.query.id
          )
            .then((resp) => {
              if (resp.code == 200) {
                if (this.$route.query.companyId) {
                  var url =
                    './test?queryIndex=1' +
                    '&companyId=' +
                    this.$route.query.companyId +
                    '&deptId=' +
                    this.$route.query.deptId
                  if (this.$route.query.postId) {
                    url = url + '&postId=' + this.$route.query.postId
                  }
                  this.$router.push(url)
                  this.loading = false;
                } else {
                  this.$router.push(
                    './test?queryIndex=' +
                      this.$route.query.queryIndex +
                      (this.$route.query.queryIndex == 3
                        ? '&theme=' + this.$route.query.theme
                        : '')
                  )
                  this.loading = false;
                }
              } else {
                this.$message.error(resp.message)
                this.loading = false;
              }
            })
            .catch(() => {
              this.$message.error('失败')
            })
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped>
@import '../../assets/style/Lean_CSS.css';
.right1 p {
  height: 30px;
  background-color: #5a8bfc;
  color: white;
  padding-left: 20px;
  padding-top: 10px;
  font-size: 14px;
}
.btn {
  position: absolute;
  right: 40px;
  bottom: 20px;
}
.btn .el-button {
  height: 40px !important;
  font-size: 18px !important;
  display: inline-flex !important;
  align-items: center !important;
}
.qustion {
  width: 100%;
  height: 20%;
  padding-top: 0px;
  padding-left: 10px;
}
</style>
